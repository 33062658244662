<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateAssetUtilizationCategoryStyled>
      <InputField :labelWidth="6" class="model">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.model.$model" placeholder="name" />
        </template>
        <template v-slot:label>
          <span>Name</span>
        </template>
        <template v-slot:errors v-if="$v.model.$dirty">
          <div class="error" v-if="!$v.model.required">Field is required</div>
          <div class="error" v-if="!$v.model.minLength">Field must have at least {{ $v.model.$params.minLength.min }}</div>
          <div class="error" v-if="!$v.model.isUnique">{{ $tc('messages.notUnique') }}</div>
        </template>
      </InputField>
    </CreateAssetUtilizationCategoryStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import { required, minLength } from 'vuelidate/lib/validators'

import ASSET_TYPES_QUERY from '#/graphql/assetUtilizationCategories/assetUtilizationCategories.gql'
import CREATE_ASSET_TYPE_MUTATION from '#/graphql/assetUtilizationCategories/createAssetUtilizationCategory.gql'

const CreateAssetUtilizationCategoryStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;
`

export default {
  components: {
    CreateAssetUtilizationCategoryStyled,
    ModelCreater,
    InputField,
  },
  data() {
    return {
      model: '',
      errors: [],
      assetUtilizationCategories: [],
      isLoading: false,
      isCreating: false,
      enableSave: false,
    }
  },
  computed: {
    title() {
      return `Create Asset Type`
    },
    canCreate() {
      return !this.$v.$invalid && !this.isCreating
    },
  },
  validations: {
    model: {
      required,
      minLength: minLength(3),
      isUnique() {
        const found = this.assetUtilizationCategories.find(item => item.name.toLowerCase() === this.model.toLowerCase())
        if (found) {
          return false
        }
        return true
      },
    },
  },
  methods: {
    async create() {
      this.isCreating = true
      const resp = await this.$apollo.mutate({
        mutation: CREATE_ASSET_TYPE_MUTATION,
        variables: {
          data: {
            name: this.model,
            symbol: this.symbol,
          },
        },
      })
      this.isCreating = false
      const id = resp?.data?.createAssetUtilizationCategory?.id
      if (resp) {
        this.$emit('reload')
        this.$router.push({
          name: 'assetUtilizationCategories',
          params: {
            id,
          },
        })
      }
    },
  },
  apollo: {
    assetUtilizationCategories: {
      query: ASSET_TYPES_QUERY,
    },
  },
}
</script>
